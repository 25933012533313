svg.MuiSelect-iconFilled {
  color: #fff !important;
  transform: translateY(50%);
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
  border-bottom: 0px solid #000;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  background-color: #010203;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  transform: translateY(-1rem) !important;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  transition: background-color 0.3s;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button:hover {
  background-color: rgba(64, 64, 64, 0.44) !important;
}

div.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input {
  padding-left: 1.9rem !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
