div.tooltip {
  font-size: 1.5rem;
  font-family: "Be Vietnam";
  background-color: #121418;
  padding: 1.75rem;
  text-align: justify;
}

div.tooltip--transform {
  font-size: 1.5rem;
  font-family: "Be Vietnam";
  background-color: #121418;
  padding: 1.75rem;
  text-align: justify;
  transform: translateY(-2rem) !important;
}

span.tooltip__arrow {
  color: #121418;
}
